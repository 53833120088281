import React from "react"
import "./faq.scss"
import Collapsible from "react-collapsible"
import RichText from "../RichText/richText"

const Features = ({ title, content, classNames }) => {
  return (
    <div className={`m-faq ${classNames}`}>
      <div className="m-faq-content">
        <Collapsible trigger={title} className="m-faq-item" openedClassName="m-faq-item"
                     triggerClassName="m-faq-item--title" triggerOpenedClassName="m-faq-item--title"
                     contentInnerClassName="m-faq-item--content" transitionTime={200} triggerTagName="div">
          <RichText content={content}/>
        </Collapsible>
      </div>
    </div>
  )
}


export default Features

