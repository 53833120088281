import React, { Component } from "react"
import { graphql } from "gatsby"
import { JumbotronPages, JumbotronPagesIcon } from "../components/Layout/Jumbotron/jumbotron"
import Layout from "../components/Layout/layout"
import Wrapper from "../components/Layout/Wrapper/wrapper"
import Faq from "../components/Modules/Faq/faq"
import { checkUtmParams } from "../services/sem/checkUtmParams"
import { Helmet } from "react-helmet"
import QuestionMarkIcon from "../images/icons/help_outline_24px.svg";

class FaqList extends Component {
  componentDidMount() {
    checkUtmParams()
  }

  render() {
    const data = this.props.data.contentfulFaq
    return (
      <Layout>
        <Helmet>
          <title>{data.title}</title>
          <meta name="description" content=""/>
        </Helmet>
        <Wrapper faq>
          <JumbotronPagesIcon margined={true} title={`Frequently Asked Questions`} icon={QuestionMarkIcon}/>
          {data.faqItem.map((item, id) => <Faq classNames={`faq-${id}`} title={item.title} key={id} content={item.content.json}/>)}
        </Wrapper>
      </Layout>
    )
  }
}

export default FaqList

export const pageQuery = graphql`
    query {
        contentfulFaq {
            slug
            title
            faqItem {
                title
                content {
                    json
                }
            }
        }
    }
`
